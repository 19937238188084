import { message, Switch } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { Card, CardTitle } from 'src/shared/widgets/Card';
import { createEntry, createTeamEntries } from 'src/api/createEntry';
import { useClub } from 'src/context/useClub';
import useForm from 'antd/es/form/hooks/useForm';
import { useState } from 'react';
import DefaultForm from './defaultForm';
// import BasketballEnglandForm from './basketballEnglandForm';
import { format } from 'date-fns';
import { stringFromJoinedParts, toTitleCase } from 'src/utils/strings';
import { Map } from 'src/shared/widgets/Map';
import { MapPin, Save } from 'react-feather';
import { PrimaryButton } from 'src/shared/buttons';
import TeamEntryForm, { teamEntriesData } from './teamEntryForm';
import { ClubCompetition } from 'src/api/getClubCompetitions';
import { createStripeCheckoutSession } from 'src/api/createStripeCheckoutSession';

export interface FormData {
  ageGroup: string;
  category: string;
  uniqueTeamGroupingCode: string;
  firstName: string;
  playerEmail: string;
  lastName: string;
  phoneNumber: string;
  allergies: string;
  britishPassport: string;
  consentsAgree: boolean;
  county: string;
  disabilities: string;
  parent1Email: string;
  parent2Email: string;
  relationship1: string;
  relationship2: string;
  emergencyFirstName1: string;
  emergencyFirstName2: string;
  emergencyLastName1: string;
  emergencyLastName2: string;
  emergencyMobileNumber1: string;
  emergencyMobileNumber2: string;
  ethnicity: string;
  dob: string;
  familyDeath: string;
  gender: string;
  headshotPhoto: File;
  homePostcode: string;
  jerseySize: string;
  medicalDetails: string;
  photographyConsentParent: string;
  photographyConsentPlayer: string;
  talentBehavioursAgree: boolean;
  membershipNumber: string;
  jerseyNumber: string;
  region: string;
  team_name?: string;
  team_manager_name?: string;
  team_manager_email?: string;
  team_manager_phone?: string;
  parent_first_name: string;
  parent_last_name: string;
  parent_mobile_number: string;
  emergency_treatment_consent: boolean;
  current_school: string;
  current_club: string;
  current_club_coach_name: string;
  current_club_coach_email: string;
  height: string;
  x_handle: string;
  instagram_handle: string;
  tiktok_handle: string;
  family_death: string;
  dietary_requirement: string;
  medication: string;
  competition_consent: string;
}

const CompetitionForm = () => {
  const [form] = useForm();
  const { competitionId } = useParams();
  const {
    club: { info, competitions },
  } = useClub();

  const [email, setEmail] = useState<string | null>(null);
  const currentCompetition = competitions.find(({ id }) => id === parseInt(competitionId!));

  // const isEnglandBasketball = info.code.includes('BE3x3');
  const [isTeamEntry, setIsTeamEntry] = useState(currentCompetition?.entryAllowed === 'team');

  const [isEntrySubmitted, setIsEntrySubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [entries, setEntries] = useState<any[]>([]);

  const submit = async (values: FormData) => {
    setLoading(true);
    try {
      const data = {
        ...values,
      };
      form.resetFields();
      await createEntry(data, parseInt(competitionId!), info.code);
      setEmail(values.playerEmail ?? values.parent1Email);
      setIsEntrySubmitted(true);
      window.scrollTo({ top: 400, behavior: 'smooth' });
      message.success('Entry created successfully');
      setLoading(false);
    } catch (err) {
      message.error('Failed to submit form');
      setLoading(false);
    }
  };

  const submitEntries = async (values: teamEntriesData) => {
    setLoading(true);
    const teamName = values.teamName;
    const teamManagerName = values.team_manager_name;
    const teamManagerEmail = values.team_manager_email;
    const teamManagerPhone = values.team_manager_phone;

    try {
      const entries = values.items.map(item => ({
        ...item,
        team_name: teamName,
        team_manager_email: teamManagerEmail,
        team_manager_name: teamManagerName,
      }));
      setEntries(entries);

      await createTeamEntries(
        entries,
        teamName,
        teamManagerName,
        teamManagerEmail,
        parseInt(competitionId!),
        info.code,
        teamManagerPhone,
      );

      form.resetFields();
      setIsEntrySubmitted(true);
      window.scrollTo({ top: 400, behavior: 'smooth' });
      setLoading(false);
    } catch (err) {
      message.error('Failed to submit form');
      setLoading(false);
    }
  };

  const formattedAddress =
    currentCompetition?.venue.address && currentCompetition.venue.city && currentCompetition.venue.postCode
      ? stringFromJoinedParts([
          currentCompetition.venue.address,
          currentCompetition.venue.city,
          currentCompetition.venue.postCode,
        ])
      : '';

  const shouldRenderMap = currentCompetition?.venue.lat && currentCompetition?.venue.lng ? true : false;

  const { category, minPlayersPerTeam, maxPlayersPerTeam, oldestDob, youngestDob } =
    currentCompetition as ClubCompetition;

  return (
    <div>
      <div className='d-flex flex-column gap-3 w-100'>
        <div className='col-12'>
          <Card>
            <div className='row'>
              <CardTitle className='mb-2'>{toTitleCase(currentCompetition?.name || '')}</CardTitle>
              <div className='col-md-6 d-flex flex-column justify-content-between'>
                {currentCompetition?.ageGroup && (
                  <p className='ts-fs-14'>
                    <span className='ts-fw-500'>Age group:</span> {toTitleCase(currentCompetition.ageGroup)}{' '}
                    {currentCompetition.secondCategoryAgeGroup &&
                      ` - ${toTitleCase(currentCompetition.secondCategoryAgeGroup)}`}
                  </p>
                )}
                {currentCompetition?.category && (
                  <p className='ts-fs-14'>
                    <span className='ts-fw-500'>Gender:</span> {toTitleCase(currentCompetition.category)}
                  </p>
                )}
                {currentCompetition?.event.start && (
                  <p className='ts-fs-14'>
                    <span className='ts-fw-500'>Start Date:</span>{' '}
                    {format(currentCompetition.event.start.replace('Z', ''), 'dd MMMM yyyy - h:mm a')}
                  </p>
                )}
                {currentCompetition?.event.start && currentCompetition?.event.end && (
                  <p className='ts-fs-14'>
                    <span className='ts-fw-500'>End Date</span>{' '}
                    {format(currentCompetition.event.end.replace('Z', ''), 'dd MMMM yyyy - h:mm a')}
                  </p>
                )}
                {currentCompetition?.event.eventEntryDeadlineDate && (
                  <p className='ts-fs-14'>
                    <span className='ts-fw-500'>Entry deadline:</span>{' '}
                    {format(currentCompetition?.event.eventEntryDeadlineDate, 'dd MMMM yyyy - h:mm a')}
                  </p>
                )}
                {currentCompetition?.product.cost && (
                  <p className='ts-fs-14'>
                    <span className='ts-fw-500'>Entry cost:</span> £{currentCompetition.product.cost}
                  </p>
                )}
                {currentCompetition?.event.description && (
                  <p className='ts-fs-14'>
                    <span className='ts-fw-500'>Information:</span> {currentCompetition.event.description}
                  </p>
                )}
                {currentCompetition?.competitionDetailsAttachment && (
                  <p className='ts-fs-14 ts-color-primary d-flex align-items-center'>
                    <Save className='me-1' size={16} />
                    <a href={currentCompetition.competitionDetailsAttachment} target='_blank' rel='noreferrer'>
                      Download more information about this event
                    </a>
                  </p>
                )}
              </div>

              <div className='col-md-6'>
                <>
                  <CardTitle className='my-2'>Address</CardTitle>
                  <a
                    className='ts-fs-13'
                    href={`https://maps.google.com?q=${formattedAddress}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div className='d-flex'>
                      <MapPin className='me-1 align-middle ts-color-primary' size={16} />
                      <p className='ts-fs-14'>{formattedAddress}</p>
                    </div>
                  </a>
                  {shouldRenderMap && (
                    <div className='mt-2'>
                      <Map lat={currentCompetition!.venue.lat!} lng={currentCompetition!.venue.lng!} zoom={15} />
                    </div>
                  )}
                  <p className='ts-fs-14 mt-2'>
                    <span className='ts-fw-500'>Contact us:</span> {info.email}
                  </p>
                </>
              </div>
            </div>
          </Card>
        </div>
        <div className='col-12'>
          {isEntrySubmitted ? (
            <div>
              {currentCompetition?.paidStatus === 'paid' ? (
                <>
                  {/* <CardTitle className='m-2'>Step {isEnglandBasketball ? '5' : '2'} - Payment</CardTitle> */}
                  <CardTitle className='m-2'>Payment</CardTitle>
                  <Card>
                    <div>
                      <p className='ts-fs-14 ts-fw-500'>Your entry will be completed once you made the payment.</p>
                      {(currentCompetition?.product.cost || currentCompetition?.teamProduct?.cost) && (
                        <>
                          {isTeamEntry ? (
                            <p className='ts-fs-14'>
                              Entry Cost: £
                              {currentCompetition.fullPrice
                                ? currentCompetition.teamProduct!.cost
                                : currentCompetition.teamProduct!.cost! * entries.length}
                              {currentCompetition.fullPrice
                                ? ''
                                : ' (£' + currentCompetition.teamProduct!.cost! + ' × ' + entries.length + ' entries)'}
                            </p>
                          ) : (
                            <>
                              <p className='ts-fs-14'>Entry Cost: £{currentCompetition.product.cost}</p>
                            </>
                          )}
                        </>
                      )}
                      <p className='ts-fs-14'>
                        {currentCompetition?.product.link || currentCompetition?.teamProduct?.link ? (
                          <>
                            <PrimaryButton
                              onClick={async () => {
                                if (isTeamEntry) {
                                  const session = await createStripeCheckoutSession(
                                    entries[0].team_manager_email ?? entries[0].email ?? entries[0].parent1Email,
                                    currentCompetition!.teamProduct!.name!,
                                    currentCompetition!.teamProduct!.cost!,
                                    currentCompetition.fullPrice ? 1 : entries.length,
                                    `competition_${currentCompetition.id}`,
                                    info.orgId,
                                  );
                                  window.open(session.url, '_blank');
                                } else {
                                  window.open(
                                    `${currentCompetition!.product
                                      .link!}?prefilled_email=${email}&client_reference_id=competition_${
                                      currentCompetition.id
                                    }`,
                                    '_blank',
                                  );
                                }
                              }}
                            >
                              <span>
                                {isTeamEntry && currentCompetition.depositProduct?.cost ? 'PAY FULL AMOUNT' : 'PAY NOW'}
                              </span>
                            </PrimaryButton>

                            {isTeamEntry && currentCompetition.depositProduct?.cost && (
                              <PrimaryButton
                                onClick={async () => {
                                  const session = await createStripeCheckoutSession(
                                    entries[0].team_manager_email ?? entries[0].email ?? entries[0].parent1Email,
                                    currentCompetition.depositProduct!.name!,
                                    currentCompetition!.depositProduct!.cost!,
                                    1,
                                    `competition_${currentCompetition.id}`,
                                    info.orgId,
                                  );
                                  window.open(session.url, '_blank');
                                }}
                                className='ms-3'
                              >
                                PAY DEPOSIT
                              </PrimaryButton>
                            )}
                          </>
                        ) : (
                          'No payment link available'
                        )}
                      </p>
                    </div>
                  </Card>
                </>
              ) : (
                <>
                  <CardTitle className='m-2'>Form submitted</CardTitle>
                  <Card>
                    <div>
                      <p className='ts-fs-14 ts-fw-500'>
                        Entry form submitted successfully return{' '}
                        <Link to='/' className='ts-color-primary'>
                          home
                        </Link>
                      </p>
                    </div>
                  </Card>
                </>
              )}
            </div>
          ) : (
            <Card>
              <div className='d-flex align-items-center justify-content-between mb-4'>
                <CardTitle>Registration</CardTitle>
                {currentCompetition?.entryAllowed !== 'single' && (
                  <div className='d-flex align-items-center gap-2'>
                    <p className='ts-fs-16 ts-fw-500 m-0'>Team entry</p>
                    <Switch
                      defaultChecked
                      onChange={() => setIsTeamEntry(!isTeamEntry)}
                      size='small'
                      checked={isTeamEntry}
                      disabled={currentCompetition?.entryAllowed === 'team'}
                    />
                  </div>
                )}
              </div>

              {isTeamEntry ? (
                // <TeamEntryFormView competition={currentCompetition} />
                <TeamEntryForm
                  submit={submitEntries}
                  dobRange={{ oldestDob, youngestDob }}
                  category={category}
                  loading={loading}
                  minPlayersPerTeam={minPlayersPerTeam}
                  maxPlayersPerTeam={maxPlayersPerTeam}
                  competition={currentCompetition}
                />
              ) : (
                <DefaultForm
                  submit={submit}
                  loading={loading}
                  dobRange={{ oldestDob: currentCompetition?.oldestDob, youngestDob: currentCompetition?.youngestDob }}
                  category={currentCompetition?.category}
                  competition={currentCompetition}
                />
              )}
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompetitionForm;
