import { Form, Input, Button, Card, Checkbox } from 'antd';
import useForm from 'antd/es/form/hooks/useForm';
import { useState } from 'react';
import { MinusCircle, PlusCircle } from 'react-feather';
import { StoreValue } from 'antd/es/form/interface';
import { ClubCompetition, EntryFormField } from 'src/api/getClubCompetitions';
import PlayerFormFields from './PlayerFormFields';
import { FormData } from './index';

export interface teamEntriesData {
  teamName: string;
  team_manager_name?: string;
  team_manager_email?: string;
  team_manager_phone?: string;
  items: FormData[];
}

export interface FormProps {
  submit: (values: teamEntriesData) => Promise<void>;
  dobRange: {
    oldestDob: string | null | undefined;
    youngestDob: string | null | undefined;
  };
  category: string | undefined;
  loading?: boolean;
  minPlayersPerTeam: number;
  maxPlayersPerTeam: number;
  competition: ClubCompetition | undefined;
}

const TeamEntryForm: React.FC<FormProps> = ({
  submit,
  dobRange,
  category,
  loading,
  minPlayersPerTeam,
  maxPlayersPerTeam,
  competition,
}) => {
  const [isOver18, setIsOver18] = useState<boolean | null>(null);
  const [form] = useForm();
  const [isMinPlayerLimitReached, setIsMinPlayerLimitReached] = useState(false);
  const [isMaxPlayerLimitReached, setIsMaxPlayerLimitReached] = useState(false);

  const handleDateOfBirthChange = (value: boolean | null) => {
    setIsOver18(value);
  };

  const addPlayer = async (add: (defaultValue?: StoreValue, insertIndex?: number) => void) => {
    try {
      const allFields = form.getFieldsError();

      // Validate all fields except termsAndConditions (because we want to wait until the last step to validate it)
      const fieldsToValidate = allFields
        .map(field => field.name)
        .filter(fieldPath => {
          const fieldPathStr = String(fieldPath);
          return !fieldPathStr.includes('termsAndConditions');
        });

      await form.validateFields(fieldsToValidate);

      add();

      const updatedFieldValues = form.getFieldsValue(true);
      if (updatedFieldValues.items) {
        updatedFieldValues.items.length >= minPlayersPerTeam && setIsMinPlayerLimitReached(true);
        updatedFieldValues.items.length === maxPlayersPerTeam && setIsMaxPlayerLimitReached(true);
      }
    } catch (error) {
      console.log('Validation errors', error);

      setTimeout(() => {
        const errorField = document.querySelector('.ant-form-item-has-error');
        if (errorField) {
          errorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 100);
    }
  };

  const recountFormItems = () => {
    const fieldValues = form.getFieldsValue();
    if (fieldValues.items) {
      if (fieldValues.items.length < minPlayersPerTeam) setIsMinPlayerLimitReached(false);
      if (fieldValues.items.length < maxPlayersPerTeam) setIsMaxPlayerLimitReached(false);
    }
  };

  const renderTeamField = (field: EntryFormField, index: number) => {
    if (!field.use) return null;

    const fieldNameMap: Record<string, string> = {
      'Institution/Team name': 'teamName',
      'Team Manager Name': 'team_manager_name',
      'Team Manager Email': 'team_manager_email',
      'Team Manager Phone Number': 'team_manager_phone',
    };

    const fieldName = fieldNameMap[field.name];
    if (!fieldName) return null;

    switch (field.name) {
      case 'Team Manager Email':
        return (
          <Form.Item
            key={`team-field-${index}`}
            label={field.name}
            name={fieldName}
            rules={[
              { required: field.required, message: `Please enter the ${field.name.toLowerCase()}` },
              { type: 'email', message: 'Please enter a valid email address' },
            ]}
          >
            <Input type='email' />
          </Form.Item>
        );

      case 'Team Manager Phone Number':
        return (
          <Form.Item
            key={`team-field-${index}`}
            label={field.name}
            name={fieldName}
            rules={[
              { required: field.required, message: `Please enter the ${field.name.toLowerCase()}` },
              {
                pattern: /^[+]?[(]?[0-9]{1,4}[)]?[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,9}$/,
                message: 'Please enter a valid phone number',
              },
            ]}
          >
            <Input type='tel' placeholder='e.g., +44 7123 456789' />
          </Form.Item>
        );

      default:
        return (
          <Form.Item
            key={`team-field-${index}`}
            label={field.name}
            name={fieldName}
            rules={[{ required: field.required, message: `Please enter the ${field.name.toLowerCase()}` }]}
          >
            <Input />
          </Form.Item>
        );
    }
  };

  const teamFields =
    competition?.teamEntryForm?.filter(field =>
      ['Institution/Team name', 'Team Manager Name', 'Team Manager Email', 'Team Manager Phone Number'].includes(
        field.name,
      ),
    ) || [];

  const defaultTeamFields: EntryFormField[] = [
    {
      id: 1,
      name: 'Institution/Team name',
      required: true,
      use: true,
      type: 'text',
      isDefault: true,
    },
    {
      id: 2,
      name: 'Team Manager Name',
      required: true,
      use: true,
      type: 'text',
      isDefault: true,
    },
    {
      id: 3,
      name: 'Team Manager Email',
      required: true,
      use: true,
      type: 'email',
      isDefault: true,
    },
    {
      id: 4,
      name: 'Team Manager Phone Number',
      required: true,
      use: true,
      type: 'text',
      isDefault: true,
    },
  ];

  const fieldsToRender = teamFields.length > 0 ? teamFields : defaultTeamFields;

  // Handle form validation errors and scroll to the first error
  const onFinishFailed = () => {
    // Add a short delay so that DOM is updated with error classes
    setTimeout(() => {
      const errorField = document.querySelector('.ant-form-item-has-error');
      if (errorField) {
        errorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
  };

  return (
    <>
      <Form
        form={form}
        name='Registration'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={submit}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={true}
      >
        {fieldsToRender.map((field, index) => renderTeamField(field, index))}

        <Form.List name='items'>
          {(fields, { add, remove }) => (
            <div>
              {fields.map(field => (
                <Card
                  size='small'
                  title={`Player ${field.name + 1}`}
                  key={field.key}
                  extra={
                    <MinusCircle
                      onClick={() => {
                        remove(field.name);
                        recountFormItems();
                      }}
                      color='#fc5452'
                      size={20}
                      role='button'
                    />
                  }
                  className='mb-4'
                >
                  <PlayerFormFields
                    fieldNamePrefix={[field.name]}
                    dobRange={dobRange}
                    category={category}
                    onDateOfBirthChange={handleDateOfBirthChange}
                    isOver18={isOver18}
                    formTemplate={competition?.singleEntryForm}
                    sportName={competition?.sport?.name || 'sports'}
                  />
                </Card>
              ))}

              {!isMaxPlayerLimitReached && (
                <div
                  className='d-flex gap-2 justify-content-center align-items-center ts-fs-18 mt-3'
                  role='button'
                  onClick={() => {
                    addPlayer(add);
                  }}
                >
                  Add Player <PlusCircle size={22} />
                </div>
              )}
            </div>
          )}
        </Form.List>

        {competition?.termAndConditionUrl && (
          <Form.Item
            name='termsAndConditions'
            valuePropName='checked'
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('You must accept the terms and conditions')),
              },
            ]}
            wrapperCol={{ span: 24 }}
            className='mb-0 mt-4'
          >
            <Checkbox>
              By checking this box, I state that I have read and understood the{' '}
              {competition?.termAndConditionUrl ? (
                <a
                  href={competition.termAndConditionUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='ts-color-primary'
                >
                  terms and conditions
                </a>
              ) : (
                'terms and conditions.'
              )}
            </Checkbox>
          </Form.Item>
        )}

        <Form.Item>
          <Button
            htmlType='submit'
            className='w-100 p-0 mt-3 border-0'
            style={{ border: 0 }}
            disabled={!isMinPlayerLimitReached}
            loading={loading}
            type='primary'
          >
            Submit Entries
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default TeamEntryForm;
