import { apiClient } from 'src/utils/apiClient';
import type { FormData } from 'src/pages/CompetitionForm';

interface Response {
  user_id: number;
}

interface TeamEntryResponse {
  user_ids: number[];
}

export async function createEntry(data: FormData, competitionId: number, siteCode: string): Promise<number> {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (key === 'headshotPhoto') {
      formData.append(key, value as File);
    } else if (typeof value === 'boolean') {
      formData.append(key, value ? '1' : '0');
    } else {
      formData.append(key, value);
    }
  });

  formData.append('medicalDetails_toggle', data.medicalDetails ? '1' : '0');

  const response = await apiClient<Response>(`/v1/website/entries/${competitionId}`, {
    method: 'POST',
    body: formData,
    siteCode,
  });

  return response.user_id;
}

export async function createTeamEntries(
  entries: FormData[],
  teamName: string,
  teamManagerName: string | undefined,
  teamManagerEmail: string | undefined,
  competitionId: number,
  siteCode: string,
  teamManagerPhone: string | undefined,
): Promise<number[]> {
  const entriesJSON = JSON.stringify(entries);

  const formData = new FormData();
  formData.append('team_entries', entriesJSON);
  formData.append('team_name', teamName);

  if (teamManagerName) {
    formData.append('team_manager_name', teamManagerName);
  }

  if (teamManagerEmail) {
    formData.append('team_manager_email', teamManagerEmail);
  }

  if (teamManagerPhone) {
    formData.append('team_manager_phone', teamManagerPhone);
  }

  const response = await apiClient<TeamEntryResponse>(`/v1/website/entries/${competitionId}`, {
    method: 'POST',
    body: formData,
    siteCode,
  });

  return response.user_ids;
}
